var home, our_services, portfolio, blog, contact_us, agency_for, web_design_intro,web_design, google_advertising_intro,google_advertising, facebook_advertising_intro,
facebook_advertising, seo_optimization,seo_optimization_intro, graphic_design_intro, graphic_design, intro_text,
web_design_text, google_advertising_text, facebook_advertising_text, seo_optimization_text, graphic_design_text,why_well, experienced_team, complete_offer, attractive_prices,
consulting, proffesional_advice, experienced_team_text, complete_offer_text, attractive_prices_text, consulting_text, proffesional_advice_text,  promote_text, categories, latest_works, branding, advertising, projects,all_works, 
more_projects, our_clients, contact, footer_text_big, footer_text_small, impresum, contact_text_big, contact_text_small, contact_informations, close, name_surname, email,
select_service, message_title,phone_number, message, send_message, next_page, previous_page, client_string, date_string, category_string, website_string, single_text;
var langType, homepage, not_found_text, thankyou_text, latest_projects, therese_corriente, nataliya_schweda, michael_zechner, branding_and_consulting, references, full_List_references;
var logo_tag,about_us, see_more, careers, upload_your_cv, apply_now, video_advertisment, online_store;
if (typeof window !== 'undefined') {
langType = window.location.pathname.split('/');
langType = langType[1];
}
 if(langType == "bs"){
    home = "Početna";
    our_services = "Naše usluge";
    portfolio = "Portfolio";
    blog = "Blog";
    careers = "Karijera";
    about_us = "O nama";
    contact_us = "Pošaljite upit";
    agency_for = "Agencija za";
    web_design_intro = "web dizajn";
    web_design = "Web dizajn";
    google_advertising_intro = "Google oglašavanje";
    google_advertising = "Google oglašavanje";
    facebook_advertising_intro = "socijalne medije";
    facebook_advertising = "Društvene mreže";
    seo_optimization_intro = "SEO optimizaciju";
    seo_optimization = "SEO optimizacija";
    graphic_design_intro = "grafički dizajn";
    graphic_design = "Grafički dizajn";
    intro_text = "Sve digitalne usluge na jednom mjestu.";
    web_design_text = "Dobra web stranica je privlačna, laka za navigaciju, uklapa se u Vaš korporativni identitet, ima dobar sadržaj i raspored elemenata, brza je i tehnički nadogradiva i još mnogo toga. Pored dizajniranja i programiranja klasičnih web stranica, radimo i najzahtjevnije online shopove, aplikacije i portale. Pri tome uzimamo u obzir vašu branšu, konkurenciju, ciljnu grupu … Radimo za tržišta zapadnog Balkana ali i višejezično za englesko, njemačko, tursko i špansko govorno područje.";
    google_advertising_text = "Većina kompanija ne uspijeva zauzeti organsku poziciju na prvoj Google-ovoj stranici sa svim bitnim ključnim riječima. Dodatna mogućnost za dobro pozicioniranja proizvoda i usluga se nudi putem Google oglasa (AdWords-a). Google oglase kreiramo samo uz prethodnu detaljnu analizu isplativosti oglašavanja za klijenta. Efikasnost kampanja poboljšavamo izradom Landing Pages specifičnih za proizvode ili usluge na koje se rade oglasi.";
    facebook_advertising_text = "Bilo da se radi o klasičnom marketingu, odnosima s javnošću, brendiranju, promociji, reputaciji na internetu, komunikaciji sa klijentima pa i direktnoj prodaji, društvene mreže zauzimaju veoma bitno mjesto u digitalnom marketingu i modernom poslovanju. Pravimo analizu društvenih mreža podobnih za Vaše tržište i oglašavamo Vas na jednoj ili više vodećih društvenih mreža: Facebook, Instagram, Twitter, LinkedIn, XING i YouTube.";
    seo_optimization_text = "Statistike kažu da se 75% ukupnih pregleda odvija na prvoj stranici na Google-u. Upravo iz tog razloga se kompanije bore za dobru poziciju na pretraživačima. Koristeći najsavremenije metode i tehnike iz SEO-oblasti, radimo na pozicioniranju Vaše web stranice u vrhu Google pretraživača. Time će se Vaš biznis uveliko isticati u odnosu na konkurenciju i imati bolje izglede da bude prihvaćen od ciljne grupe.";
    graphic_design_text = "Prvo što privlači Vaše klijente je atraktivan dizajn, ali dizajn treba biti i funkcionalan te da ispunjava svrhu prodaje proizvoda ili usluga. Naš tim dizajnera sprovodi Vaše ideje u prvoklasne grafičke radove. Također Vam izlazimo u susret sa originalnim i kreativnim konceptualnim rješenjima, te postavljamo osnove za Vaš korporativni dizajn koji Vas dugoročno prati kroz Vaše poslovanje.";
    why_well = "Zašto Well?";
    experienced_team = "Iskusan tim";
    complete_offer = "Kompletna ponuda";
    attractive_prices = "Atraktivne cijene";
    consulting = "Konzalting";
    experienced_team_text = "“Well” tim je sačinjen od talentiranih i iskusnih pojedinaca sa dugogodišnjim nacionalnim i internacionalnim iskustvom iz oblasti marketinga, ekonomije i grafičkog dizajna. Mnogobrojne reference kao i stručna sprema našeg tima će zasigurno biti plus i za Vaše poslovanje. Rado odgovaramo kako na jednostavne tako i na najzahtjevnije upite klijenata po pitanju domaćeg ili inostranog tržišta. Radujemo se saradnji sa Vama i novim iskustvima! ";
    complete_offer_text = "Prema dogovoru pratimo naše klijente od usluga konzaltinga i izrade marketinških planova, preko grafičkog, web i korporativnog dizajna, pa sve do programiranja kompleksnih web stranica, online trgovina i aplikacija. Naš spektar ponude zaokružujemo sveobuhvatnom ponudom iz oblasti digitalnog marketinga (SEO, oglašavanje na Google-u i društvenim mrežama – Facebook, Instagram, LinekdIn, Twitter, XING, YouTube).";
    attractive_prices_text = "Iako smo prisutni na inostranom tržištu rado stojimo na raspolaganju domaćim firmama koje žele raditi na domaćem ali i onima  koje trebamo pripremiti za inostrano tržište. Uz maksimalnu profesionalnost i predanost poslu kojeg radimo, svim našim klijentima nudimo pristupačne cijene, kako pojedinačnih proizvoda, tako i specijalno kreiranih paketa usluga. Ponude ispostavljamo samo za usluge koje će po našem mišljenju biti korisne Vašem poslovanju. ";
    consulting_text = "Veoma je mali broj komapnija koje su uspjele razviti uspješan biznis bez stručnog savjeta. U tu svrhu se angažuju konzultanti koji objektivno analiziraju Vašu situaciju i na osnovu rezultata analize Vas savjetuju po pitanju trenutnog i budućeg poslovanja. Nerijetko se susrećemo sa firmama nezadovoljnim učinkom vlastitih marketinških kampanja. Investirali su “brdo” novca a nisu vidjeli efekat." ;
    promote_text = "Spremni da promovišete Vaš biznis?";
    proffesional_advice = "Profesionalni savjeti";
    proffesional_advice_text = "Koristeći dugogodišnja iskustva iz oblasti marketinga, ekonomije i grafičkog dizajna savjetujemo naše klijente u svim fazama zajedničkog rada na projektima. Svoje vrijeme i iskustvo rado dijelimo sa Vama, dajući Vam stručne savjete i ideje korisne za Vaš biznis. Rado ste viđeni gosti i na stručnim seminarima koje organizujemo za firme i studente. Nudimo Vam besplatno prvo savjetovanje kako biste imali priliku čuti naše mišljenje o mogućnostima unapređenja Vašeg poslovanja, upoznati nas bolje, te lakše donijeti odluku po pitanju buduće saradnje.";
    categories = "Kategorije";
    latest_works = "Najnoviji radovi";
    branding = "Brendiranje";
    advertising = "Digitalni marketing";
    projects = "Projekti";
    all_works = "Svi projekti";
    more_projects = "Više projekata";
    our_clients = "Naši klijenti";
    contact = "Kontakt";
    footer_text_big = "Spremni za korak naprijed u vašem poslovanju?";
    footer_text_small = "Kontaktirajte nas, okoristite se besplatnim savjetovanjem i uvjerite se u našu stručnost!";
    impresum = "Impressum";
    contact_text_big = "Za sva pitanja Vam rado stojimo na raspolaganju putem telefona ili email-a. Radujemo se Vašem upitu.";
    contact_text_small = "Pojasnite razlog Vašeg upita te dogovorite besplatan termin. Radnim danima odgovaramo u roku od 24 sata.";
    contact_informations = "Kontakt informacije:";
    close = "Zatvori";
    name_surname = "Vaše ime i prezime";
    email = "Vaš email";
    select_service = "Izaberite uslugu";
    message_title = "Naslov poruke";
    phone_number = "Broj telefona";
    message = "Vaša poruka";
    send_message = "Pošaljite poruku";
    next_page = "Sljedeća stranica";
    previous_page = "Prethodna stranica";
    client_string = "Klijent";
    date_string = "Datum";
    category_string = "Kategorija";
    website_string = "Web stranica";
    single_text = "Zainteresovani ste za rad sa nama?";
    homepage = "početnu stranicu";
    not_found_text = "Oooops, stranica koju tražite ne postoji. Posjetite ";
    thankyou_text = "Hvala Vam, vaša poruka je primljena. U narednih 48 sati možete očekivati naš odgovor."
    latest_projects = "Najnoviji projekti";
    branding_and_consulting = "Branding i Konzalting";
    therese_corriente = "Ne možemo biti zadovoljniji stranicom/online prodavnicom koju je za nas kreirao Well Business Promotion tim. Moderna je, privlačna i jako jednostavna za navigaciju. S obzirom da sami održavamo i uređujemo našu stranicu, jednostavan pristup administraciji je također bio bitan. Zahvaljujući video instrukcijama pruženim od strane osoblja Well Business Promotion i njihovoj odgovornosti, sve je bilo uredu.";
    nataliya_schweda = "Bilo da se radi o redizajnu web stranice, tehničkim ispravkama, SEO optimizaciji ili savjetima i trikovima u “svakodnevnom IT životu”, jako cijenimo saradnju sa Well Business Promotion. “Well” ima efikasan, jednostavan i profesionalan pristup. Cijenimo jednostavnu saradnju koja daje vrijedne rezultate i pouzdanu podršku. Kompetentan i prijateljski savjet zaokružuje pozitivnu sliku. Rado preporučujemo “Well tim”.";
    michael_zechner = "Uvijek je zadovoljstvo raditi sa gosp. Ibrišimovićem i njegovim Well Promotion timom. Uvijek su jako empatični, profesionalni i brzi u odgovoru. Dobili smo zaista sjajnu prodajnu i marketinšku vrijednost i sve to za pristupačnu cijenu. Svima preporučujem Well Promotion za izradu njihove buduće web stranicu.";
    references = "Reference";
    full_List_references = "Sve reference";
    logo_tag = "Agencija za digitalni marketing sa uslugama konzaltinga, web i grafičkog dizajna, SEO, Google oglašavanja i reklamiranja putem društvenih mreža - Facebook, Instagram, Linkedin, Twitter...";
    see_more = "Pogledajte više"; 
    upload_your_cv = "Priložite vaš CV";
    apply_now = "Aplicirajte";
    video_advertisment = "Video oglašavanje";
    online_store = "Online trgovine";
  }

 else{
     home = "Home";
     our_services = "Our services";
     portfolio = "Portfolio";
     blog = "Blog";
     careers = "Careers";
     about_us = "About us";
     contact_us = "Contact us";
     agency_for = "Agency for";
     web_design_intro = "web design";
     web_design = "Web design";
     google_advertising_intro = "Google advertising";
     google_advertising = "Google advertising";
     facebook_advertising_intro = "social media";
     facebook_advertising = "Social media";
     seo_optimization_intro = "SEO optimization";
     seo_optimization = "SEO optimization";
     graphic_design_intro = "graphic design";
     graphic_design = "Graphic design";
     consulting = "Consulting";
     intro_text = "All digital services in one place.";
     web_design_text = "A good website is attractive, easy to navigate, fits into your corporate identity, has good content and layout elements, is fast and technically upgradable. In addition to designing and programming classic websites, we also work on most demanding online shops, applications and portals. In doing so, we take into account your industry, competition, target group ... We work multilingual for the English, German, Turkish, Spanish and Western Balkans speaking areas.";
     google_advertising_text = "Most companies fail to take an organic position on the first Google page with all relevant keywords. An additional opportunity for the good positioning of products and services is offered through Google Ads (AdWords). We create Google ads only with a previous detailed analysis of the cost-effectiveness of advertising. We improve the effectiveness of our campaigns by creating Landing Pages specific for the products or services on which our ads work.";
     facebook_advertising_text = "Whether it's classic marketing, public relations, branding, promotion, reputation on the Internet, communication with clients, and direct sales, social media takes a very important place in digital marketing and modern business. We make an analysis of your market and advertise you on one or more of the leading social networks: Facebook, Instagram, Twitter, LinkedIn, XING or YouTube.";
     seo_optimization_text = "Statistics say 75% of the total review takes place on the first page on Google. For this reason, companies are struggling for a good position on the search engines. Using state-of-the-art methods and techniques from the SEO field, we're working on positioning your website at the top of the Google search engine. This will greatly expose your business in relation to competition.";
     graphic_design_text = "The first thing that attracts your customers is an attractive design, but the design should be functional and meet the purpose of selling products or services. Our design team carries your ideas into first-class graphic works. We also meet you with original and creative conceptual solutions, and set the basics for your corporate design that keeps you in the long run through your business.";
     consulting_text = "There are very few companies that have managed to develop a successful business without professional advice. For this purpose, consultants who objectively analyze your situation are engaged and based on the results of the analysis, they advise you on current and future business. We are often confronted with companies with unsatisfactory effect of our own marketing campaigns. They invested a 'ton' of money and did not see the effect." ;
     why_well = "Why Well?";
     experienced_team = "Experienced team";
     complete_offer = "Complete offer";
     attractive_prices = "Attractive prices";
     proffesional_advice = "Proffesional advice";
     experienced_team_text = "The 'Well' team is composed of talented and experienced individuals with years of national and international experience in the field of marketing, economics and graphic design. The numerous references as well as the professionalism of our team will surely be a plus for your business. We are pleased to answer both the simple and the most demanding customer inquiries regarding the domestic or foreign markets. We look forward to working with!";
     complete_offer_text = "According to the agreement, we offer our clients consulting services, development of marketing plans, graphic, web and corporate design as well as programming of web pages, online stores and applications. Our range of offer is rounded off with a comprehensive services in the field of digital marketing (SEO, advertising on Google and social media - Facebook, Instagram, LinekdIn, Twitter, XING, YouTube).";
     attractive_prices_text = "We are present in the markets of Eastern and Western Europe as well as on other continents. We are pleased to be at the disposal of companies that want to enter the European markets of the German, English, Spanish or South Slavic speaking areas. With professionalism and dedication to our business, we offer all our clients affordable prices for individual products and services, but also for specially created service packages. We only offer you services that will be useful to you in our opinion.";
     proffesional_advice_text = "Using many years of experience in marketing, economics and graphic design, we advise our clients at all stages of joint project work. We share our time and experience with you, giving you professional tips and ideas useful for your business. We are glad to see you at our expert seminars for companies and students. We offer you a free initial consultation so that you have the opportunity to hear our opinion on the possibilities of improving your business, get to know us better, and easier to make a decision regarding the potential cooperation.";
     promote_text = "Ready to promote Your business?";
     categories = "Categories";
     latest_works = "Latest works";
     branding = "Branding";
     advertising = "Digital marketing";
     projects = "Projects";
     all_works = "All works";
     more_projects = "More projects";
     our_clients = "Our clients";
     contact = "Contact";
     footer_text_big = "Ready to step forward in your business?";
     footer_text_small = "Contact us, take advantage of free counseling and find out about our expertise!";
     impresum = "Impressum";
     contact_text_big = "For any questions, we are happy to be available by phone or email. We look forward to your inquiry.";
     contact_text_small = "Explain the reason for your inquiry and arrange a free term. We reply within 24 hours on weekdays.";
     contact_informations = "Contact informations:";
     close = "Close";
     name_surname = "Your name and surname";
     email = "Your email";
     select_service = "Select service";
     message_title = "Message title";
     phone_number = "Phone number";
     message = "Your message";
     send_message = "Send message";
     next_page = "Next page";
     previous_page = "Previous page";
     client_string = "Client";
     date_string = "Date";
     category_string = "Category";
     website_string = "Website";
     single_text = "Are you interested in working with us?";
     homepage = " homepage";
     not_found_text = "Oooops, the page you are searching for does not exist. Go back to";
     thankyou_text = "Thank you, your message has been received. In the next 48 hours you can expect our answer."
     latest_projects = "Latest projects";
     branding_and_consulting = "Branding and Consulting";
     therese_corriente = "We couldn't be more pleased with the website/online shop created for us by Well Business Promotion team. It is current, attractive, and super easy to navigate. Since we maintain and edit our own website, ease of use on the administrative end was important as well. With the video instructions provided and the responsiveness of the staff at Well Business Promotion, it is all good!";
     nataliya_schweda = "Whether redesigning the website, technical adjustments, SEO or tips & tricks in the «IT everyday life», we greatly appreciate the cooperation with Well Business Promotion. „Well“ has an  efficient, uncomplicated and professional approach. All in a sympathetic way. We value uncomplicated collaboration that gives valuable input and reliable support. Competent and friendly advice rounds off the positive image. We can gladly recommend the „Well Team“.";
     michael_zechner = "It is always a pleasure to work with Mr. Ibrisimovic and his Wellpromotion team. They are always high empathic, high professional and fast in there answers. We really get a great sales and marketing value for also a fair price. I can really recommend Wellpromotion to everyone for the next digital webpage, sales and activity requests.";  
     references = "References"; 
     full_List_references = "All references";
     logo_tag = "Digital Marketing Agency with services for consulting, website and graphic design, SEO, Google Ads and social media (Facebook, Instagram, Linkedin, Twitter...) advertising";
     see_more = "See more";
     upload_your_cv = "Upload your CV";
     apply_now = "Apply now";
     video_advertisment = "Video advertisment";
     online_store = "Online Stores"
    } 

 export {home, our_services, portfolio, blog, careers, contact_us, agency_for, web_design,web_design_intro, google_advertising,google_advertising_intro, facebook_advertising,
    facebook_advertising_intro, seo_optimization,seo_optimization_intro,  graphic_design,graphic_design_intro, intro_text,upload_your_cv,apply_now,online_store,
    web_design_text, google_advertising_text, facebook_advertising_text, seo_optimization_text, graphic_design_text, proffesional_advice_text, why_well, experienced_team, complete_offer, attractive_prices,
    consulting, experienced_team_text, proffesional_advice, complete_offer_text, attractive_prices_text, consulting_text,  promote_text, categories, latest_works, branding, advertising, projects,all_works, 
    more_projects, our_clients, contact, footer_text_big, footer_text_small, impresum, contact_text_big, contact_text_small, contact_informations, close, name_surname, email,
    select_service, message_title, phone_number, message, send_message, next_page, previous_page, client_string, date_string, category_string, website_string, single_text, homepage, not_found_text,
     thankyou_text, latest_projects, therese_corriente, nataliya_schweda, michael_zechner, branding_and_consulting, references, full_List_references, logo_tag, about_us, see_more, video_advertisment};